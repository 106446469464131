<template>
  <div>
    <b-card>
      <ValidationObserver ref="country" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
          <b-row>
            <b-col md="6">
              <input-form
                v-model="country.country.translations[0].name"
                :name="$t('countries.nameAr')"
                :label="$t('countries.nameAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <input-form
                v-model="country.country.translations[1].name"
                :name="$t('countries.nameEn')"
                :label="$t('countries.nameEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
          </b-row>

          <form-btns
            v-if="currentPage != itemPages.view"
            :label="submitLabel"
            :loading="loading"
          />
        </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <cities :countryId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import countriesServices from "../services/countries";
import Country from "../models/Country";
import Cities from "../../cities/views/Cities.vue";
import moduleItemMixin from "../../../../Mixins/moduleItemMixin";

export default {
  mixins: [moduleItemMixin],
  components: { Cities },
  data() {
    return {
      country: new Country(),
    };
  },
  methods: {
    async create() {
      countriesServices
        .create({ translations: this.country.country.translations })
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "generalSettings.countries" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async update() {
      countriesServices
        .update(this.id, { translations: this.country.country.translations })
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "generalSettings.countries" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    if (this.id) {
      countriesServices.findOne(this.id).then((response) => {
        this.country.fillData(response.data.data);
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
};
</script>
