export default class Country {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.country = {
      translations: [
        { id: null, name: '', lang: 'ar' },
        { id: null, name: '', lang: 'en' }
      ]
    }
  }

  fillData (data) {
    if (data) {
      const itemAr = data.translations?.find(o => o.lang === 'ar')
      const itemEn = data.translations?.find(o => o.lang === 'en')

      this.country.translations[0].id = itemAr ? itemAr.id : null
      this.country.translations[0].name = itemAr ? itemAr.name : ''
      this.country.translations[1].id = itemEn ? itemEn.id : null
      this.country.translations[1].name = itemEn ? itemEn.name : ''
    } else {
      this.setInitialValue()
    }
  }
}
